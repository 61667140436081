@import url("https://fonts.googleapis.com/css2?family=Muli:wght@700&display=swap");
@import "../src/styles/variables";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ui.dimmer {
  position: fixed !important;
  z-index: 9999 !important;
}

.c-image-bill {
  display: flex;
  margin: auto;
  width: 400;
  flex-wrap: wrap;
}

.c-text-white {
  color: $text-color !important;
}

@each $name, $color in $buttons {
  .c-icon-#{$name} {
    color: #{$color} !important;
  }

  .c-text-#{$name} {
    color: #{$color} !important;
  }

  .c-checkbox-#{$name} {
    color: #{$color} !important;

    &:checked {
      color: #{$color} !important;
    }
  }

  .c-grid-btn-#{$name} {
    color: #{$color} !important;

    &:hover {
      color: white !important;
      background-color: #{$color} !important;
    }
  }

  .c-grid-tooltip-#{$name} {
    color: #{$color} !important;
  }

  .c-icon-#{$name},
  .c-font-#{$name} {
    color: #{$color} !important;
  }
  .c-background-color-#{$name} {
    background-color: #{$color} !important;
  }
  .c-border-botom-#{$name} {
    border-bottom: 1px solid !important;
    color: #{$color} !important;
  }

  .c-border-top {
    border-top: 1px solid !important;
    color: rgba(128, 128, 128, 0.25) !important;
  }

  .c-item-menu-#{$name} {
    color: #000 !important;
    &:hover {
      background-color: #{$color} !important;
    }
  }

  .c-btn-#{$name} {
    color: white !important;
    box-shadow: none;
    text-transform: none;
    padding: 6px 12px;
    border: 1px solid;
    line-height: 1.5;
    background-color: #{$color} !important;
    border-color: #{$color} !important;
    font-weight: 700 !important;
    cursor: pointer !important;
    font: "Roboto", sans-serif;

    & span {
      font-size: 12px !important;
    }

    &:hover,
    :active,
    :focus {
      background-color: saturate($color, 10%) !important;
      border-color: saturate($color, 10%) !important;
    }

    &:disabled {
      background-color: $disabled-color !important;
      border-color: $disabled-color !important;
    }

    &.c-btn-square {
      padding: 8px 10px !important;
    }
  }
}

//Custom margins
@for $i from 0 through 100 {
  .c-mt-#{$i} {
    margin-top: #{$i}px !important;
  }

  .font-size-#{$i} {
    font-size: #{$i}px !important;
  }
  .c-mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  .c-ml-#{$i} {
    margin-left: #{$i}px !important;
  }

  .c-mr-#{$i} {
    margin-right: #{$i}px !important;
  }

  .c-w-#{$i} {
    width: #{$i}"%" !important;
  }

  .c-wp-#{$i} {
    width: #{$i}px !important;
  }

  .c-maxw-#{$i} {
    max-width: #{$i}px !important;
  }

  .c-mwp-#{$i} {
    min-width: #{$i}px !important;
  }

  .c-hp-#{$i} {
    height: #{$i}px !important;
  }

  .c-p-#{$i} {
    padding: #{$i}px !important;
  }

  .c-pt-#{$i} {
    padding-top: #{$i}px !important;
  }

  .c-pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }

  .c-m-#{$i} {
    margin: #{$i}px !important;
  }

  .c-m-n-#{$i} {
    margin: -#{$i}px !important;
  }

  .c-fs-#{$i} {
    font-size: #{$i}px !important;
  }
}

.c-text-center {
  text-align: center !important;
}

.c-self-center {
  align-self: center !important;
}

.c-self-end {
  align-self: flex-end !important;
}

.c-just-center {
  justify-content: center !important;
}

.c-text-right {
  text-align: right !important;
}

.c-form {
  padding: 10px 15px 20px 15px;
}
.c-inline-flex {
  display: inline-flex !important;
}
.c-none {
  display: none !important;
}

.swal-text {
  text-align: center !important;
}

.c-text-with-icon {
  display: flex !important;
  align-items: center !important;
}

.c-cursor-pointer {
  cursor: pointer !important;
}

// Grids
.c-grid {
  padding: 10px;
  border-left: 4px solid $fidel-primary !important;

  .c-grid-header {
    & h2 {
      color: black !important;
    }
  }

  .c-grid-header-filter {
    & h5 {
      color: gray !important;
    }
  }

  & .c-grid-btn {
    float: right;
  }
}

.c-grid-results {
  padding: 10px;

  & thead {
    background-color: $table-color !important;

    & tr th {
      text-align: center !important;
      line-height: 0.8rem !important;
      color: white;
      text-transform: uppercase !important;
      font-weight: 700;
    }
  }

  & tbody {
    & tr td {
      text-align: center !important;
      //text-transform: uppercase !important;
      font-weight: 500;
    }
  }

  .c-pagination {
    li {
      button {
        color: black !important;
        &:hover,
        :active {
          background-color: $fidel-primary !important;
          color: white !important;
        }
      }
    }
  }
}

.frm-body {
  background-color: #d8d8d6 !important;
  color: white !important;
  border-radius: unset !important;
  border: none !important;
  /*Para img en el header del form*/
  padding-top: 0px !important;
  margin-top: 10px !important;

  & .frm-btn {
    background-color: #c0272a !important;
    border-color: #c0272a !important;
    border-radius: unset !important;
    text-transform: uppercase !important;
    padding: 15px !important;
    font-weight: 800 !important;
    font-size: 13px !important;
    font-family: "Roboto", sans-serif;
    font-weight: 900;

    &:hover {
      background-color: #d41a1d !important;
    }
  }

  & .incon-input i {
    margin: unset !important;
  }

  & .icon-input,
  .frm-input {
    border: unset !important;
    border-radius: unset !important;
  }
}

.c-react-select > * {
  z-index: 999 !important;
}

.c-loader,
.c-menu {
  z-index: 9999 !important;
}

.c-modal {
  z-index: 998 !important;
}

.c-navbar-button {
  border-radius: unset !important;
  padding: 2px 20px !important;
}

.c-appbar {
  background-color: #000000 !important;
  z-index: 9999 !important ;
  & .c-appbar-left {
    color: #fff !important;
  }

  & .c-appbar-right {
    margin-left: auto !important;

    & .c-items {
      display: inline-block !important;
    }

    & h4 {
      color: $primary-color !important;
    }

    .c-logout-icon {
      color: $primary-color !important;
      cursor: pointer !important;
    }

    .c-username {
      margin-right: 5px !important;
      padding-right: 5px !important;
      border-right: 2px solid gray;

      & p {
        text-align: right !important;
        font-size: 11px !important;
        color: gray;
      }
    }

    .c-change-company-btn {
      font-size: 14px !important;
      border-radius: unset !important;
      color: gray !important;
      // border-right: 1px solid $primary-color !important;

      &:hover {
        background-color: unset !important;
      }
    }
  }
}
.c-appbar-loged {
  background-color: #6ea877 !important;
  @extend .c-appbar;
}

.c-appbar-login {
  background-color: rgba(0, 0, 0, 0) !important;
}

.c-company-dialog {
  & h2 {
    color: $primary-color !important;
  }

  & .c-company-dialog-item {
    &:hover {
      color: white !important;
      background-color: $primary-color !important;
    }
  }
}

.c-dialog-overflow {
  overflow-y: visible !important;
}

.c-table {
  h6 {
    font-size: 11px;
    font-weight: 200;
    text-transform: none;
  }

  & thead {
    background-color: $table-color;
    opacity: 0.8;

    & tr th {
      text-align: center !important;
      line-height: 0.8rem !important;
      color: white;
      text-transform: uppercase !important;
      font-weight: 700;
      opacity: 1;
    }
  }

  & tbody {
    & tr td {
      text-align: center !important;
      // text-transform: uppercase !important;
      font-weight: 500;
      font-size: 11px;
      padding: 5px;
    }
  }

  &-totals {
    background-color: $primary-color !important;
    color: white !important;
    font-weight: 600 !important;
  }

  &-usmall td,
  th {
    padding: 10px 7px 10px 7px !important;
  }

  &-current {
    border-left: 10px solid green;
  }

  &-current-message {
    color: green !important;
    font-weight: 600 !important;
  }

  &-child {
    td {
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-bottom: 15px !important;
    }

    & > td {
      padding-top: 0px !important;
      padding-bottom: 5px !important;
    }

    & thead {
      background-color: #565555 !important;
    }

    .c-table {
      background-color: #80808038;
    }
  }
}

.c-tab {
  &-indicator {
    background-color: $primary-color !important;
  }
}

.c-text-upper h2 {
  text-transform: uppercase !important;
}

.test {
  z-index: 99999 !important;
}

.c-viewer {
  p {
    font-size: 24px;
    color: white;
    font-weight: 700;

    span {
      display: none;
    }
  }
}

.c-first-uppercase {
  text-transform: capitalize;
}

.login {
  min-height: 100vh !important;

  &__card {
    @extend .c-p-15;

    img {
      width: 30% !important;
    }

    @media (max-width: 900px) {
      img {
        width: 50% !important;
      }
    }
  }
}

.controller-detail {
  h4 {
    font-size: 1.3em;
    font-weight: 700 !important;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
  }
}
.c-icon-instagram {
  :hover {
    color: #d4135d;
  }
}
.c-link-phone {
  color: #fff !important;
  font-size: 12px !important;
  &:hover {
    text-decoration: underline !important;
  }
  @media (max-width: 606px) {
    font-size: 10px !important;
  }
  @media (max-width: 460px) {
    font-size: 7px !important;
  }
}
.item-rigth {
  display: inline-block !important;
  cursor: pointer;
  font-size: 12px;
  padding-left: 5px;
  font-weight: bold;
  @media (max-width: 606px) {
    font-size: 10px !important;
  }
  @media (max-width: 460px) {
    font-size: 7px !important;
  }
}
.item-left {
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  @media (max-width: 606px) {
    font-size: 10px !important;
  }
  @media (max-width: 460px) {
    font-size: 7px !important;
  }
}
.hover-text-navbar {
  &:hover {
    color: #53c145 !important;
  }
}
.poppins {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
.c-icon-navbar {
  @media (max-width: 606px) {
    font-size: 15px !important;
  }
  @media (max-width: 460px) {
    font-size: 10px !important;
  }
}
.background-login {
  background-image: url(./img/background_login.jpg);
  background-size: cover !important;
  background-position: center !important;
  background-color: rgba(88, 95, 105, 0.2) !important;
  opacity: 1;
  visibility: visible;
}
.mb-rem {
  margin-bottom: 1.5rem !important;
}
.c-generate-pass {
  &:hover {
    color: #1a910d !important;
    text-decoration: underline !important;
  }
}
.c-text-bold {
  font-weight: bold !important;
}
.c-item-f-r {
  float: right !important;
}
.c-p-default {
  padding: 15px 0px 30px 130px !important;
}
.c-rows-elipsis {
  max-width: 150px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.btn-methods {
  color: white !important;
  box-shadow: none;
  text-transform: none !important;
  padding: 6px 12px;
  border: 1px solid;
  line-height: 1.5;
  background-color: #8b9aa3 !important;
  border-color: #abbac3 !important;
  font-weight: 700 !important;
  cursor: pointer !important;
  font: "Roboto", sans-serif;

  & span {
    font-size: 12px !important;
  }

  &:hover,
  :active,
  :focus {
    background-color: rgba(139, 154, 163, 0.8) !important;
    border-color: rgba(139, 154, 163, 0.8) !important;
  }

  &:disabled {
    background-color: $disabled-color !important;
    border-color: $disabled-color !important;
  }

  &.c-btn-square {
    padding: 8px 10px !important;
  }
}