@import "../styles/variables";

.c-list {
  padding-top: 0px !important;
  width: 190px !important;

  &-item,
  &-collapse-item {
    padding: 8px !important;

    span {
      font-size: 11px !important;
      font-weight: 700 !important;
    }
  }

  &-item {
    span {
      color: $text-color-black !important;
    }

    &:hover {
      span,
      svg {
        color: white !important;
      }

      background-color: $fidel-primary !important;
    }
  }

  &-collapse {
    &-item {
      color: $color-text-primary !important;
    }

    &-body {
      &-list {
        padding: 0px !important;
      }
    }
  }
}
