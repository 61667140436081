$primary-color: #ec1b24;
$fidel-secondary: #d17a29;
$fidel-secondary-icon: #e99343;
$primary-color-confirm: #e20613;
$secondary-color: #2f2e2e;
$text-color: white;
$disabled-color: gray;
$color-text-gray: #555;
$color-text-green: #53c145;
$text-color-black: #000000;
$button-gray-dollar: #abbac3;
$yellow-item: #fee188;
$fidel-primary: #6ea877;
$table-color: #727272;
$green-ligth: #cce2c1;
$buttons: (
  "primary": $primary-color,
  "primary-confirm": $primary-color-confirm,
  "success": #4caf50,
  "cancel": #dc3545,
  "info": #0063cc,
  "edit": #f0ad4e,
  "disabled": $disabled-color,
  "fidel-secondary": $fidel-secondary,
  "gray": $color-text-gray,
  "green": $color-text-green,
  "white": $text-color,
  "black": $text-color-black,
  "fidel-icon": $fidel-secondary-icon,
  "gray-dollar": $button-gray-dollar,
  "yellow": $yellow-item,
  "fidel-green": $fidel-primary,
  "green-ligth": $green-ligth,
);
$chips: (
  "pending": gray,
  "confirmed": green,
  "canceled": red,
  "expired": orange,
);

$color-text-primary: #2f2e2e;
$color-text-success: #4caf50;
